






















































import QrCodeService from "@/services/QrCodeService";
import LocationService from "@/services/LocationService";
import { Component, Vue } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class QrCode extends Vue {
  private root: string = process.env.VUE_APP_API_URL;
  private locations: any[] = [];
  private locationUuid: string = "";
  private mainProps = { width: 200, height: 200 };
  private templateNames: any = {
    location: "Anwesenheitsliste",
    nextevent: "Registrierungen",
  };

  download(template: string) {
    QrCodeService.download(this.locationUuid, template).then(
      (response: any) => {
        var downloadURL = URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const anchor = document.createElement("a");
        anchor.download = `${(this.locations.filter((l: any) => l.uuid === this.locationUuid)[0] || { name: "Alle" }).name}-${this.templateNames[template]}.pdf`;
        anchor.href = downloadURL;
        anchor.target = "_blank";
        anchor.click();
      }
    );
  }

  mounted() {
    LocationService.all("name").then((loc) => {
      this.locations = loc.data.content;
    });
  }
}
